import { List, ListItem, ListItemAvatar, ListItemText, Typography, useTheme } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { mdiFileWord } from '@mdi/js';
import { Icon } from '@mdi/react';
import React from 'react';
import Header from '../../components/header';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import { getApiUrl } from '../../utils/functions';
import { useGlobalStyles } from '../../utils/styles';

const AccidenteTrabajo = () => {
  const theme = useTheme();
  const globalClasses = useGlobalStyles();

  const requisitos: string[] = [
    'Oficio de notificación del ente público dirigido al L.C. Carlos Rafael de Jesús Alipi Mena encargado de despacho de la Dirección General del ISSET.',
    'Formato de reporte de Accidente de Trabajo o Enfermedad Profesional.',
    'Último recibo de pago de nómina del trabajador.',
    'Identificación oficial vigente INE.',
    'Comprobante de domicilio vigente (luz, agua, teléfono o predial).'
  ];

  const defuncion: string[] = [
    'Acta de defunción.',
    'Certificado de necropsia o dispensa de ésta, incluyendo examen toxicológico.',
    'Copia certificada de la Averiguación Previa del Ministerio Público.'
  ];

  const accidentesTrabajo: { name: string, to: string, icon: any, size: string }[] = [
    {
      name: 'Enfermedad profesional',
      to: `${getApiUrl('portal')}/static/docs/servicios-prestaciones/accidente-trabajo/enfermedad-profesional.docx`,
      icon: <Icon path={mdiFileWord} color={blue[400]} size={1.7} />,
      size: '41 KB'
    },
    {
      name: 'Accidente de trabajo',
      to: `${getApiUrl('portal')}/static/docs/servicios-prestaciones/accidente-trabajo/accidente-trabajo.docx`,
      icon: <Icon path={mdiFileWord} color={blue[400]} size={1.7} />,
      size: '46 KB'
    }
  ];

  return (
    <Layout>
      <SEO title='Accidente de trabajo' />

      <div className={globalClasses.container}>
        <Header title='Notificación de accidente de trabajo y/o enfermedad profesional' />

        <div className={globalClasses.content}>
          <Typography>Si un trabajador sufrió de algún accidente o padece de una enfermedad que considere que son derivados o están relacionados con las actividades laborales que desempeña, debe ser calificado por el Departamento de Medicina Laboral.</Typography>

          {requisitos.length > 0 ? (
            <>
              <Typography variant='body1' style={{ fontWeight: 'bold', marginTop: theme.spacing(3) }}>Requisitos</Typography>
              <ul>
                {requisitos.map((req, index) => <li key={index}><Typography>{req}</Typography></li>)}
              </ul>
            </>
          ) : null}

          {defuncion.length > 0 ? (
            <>
              <Typography variant='body1' style={{ fontWeight: 'bold', marginTop: theme.spacing(3) }}>En caso de defunción, además</Typography>
              <ul>
                {defuncion.map((def, index) => <li key={index}><Typography>{def}</Typography></li>)}
              </ul>
            </>
          ) : null}

          <Typography variant='body1' style={{ fontWeight: 'bold', marginTop: theme.spacing(3), marginBottom: theme.spacing(3) }}>Formatos descargables</Typography>

          <List>
            {accidentesTrabajo.map((accidente, index) => (
              <a key={index} download href={accidente.to} rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'none' }}>
                <ListItem button>
                  <ListItemAvatar>{accidente.icon}</ListItemAvatar>
                  <ListItemText primary={accidente.name} secondary={accidente.size} />
                </ListItem>
              </a>
            ))}
          </List>

          <Typography variant='body1' style={{ marginBottom: theme.spacing(3), marginTop: theme.spacing(3) }}>En cumplimiento al Reglamento de la Ley de Seguridad Social del Estado de Tabasco.</Typography>

          <Typography variant='body1'><b>Artículo 95.</b> "El ente público estará obligado a hacer del conocimiento del ISSET, por oficio mediante acta circunstanciada que describa los hechos, el accidente de trabajo <b>dentro de los tres días hábiles siguientes a que se produzca</b>. En caso de exceder dicho término y no justificarlo a satisfacción del ISSET, éste no le reconocerá como accidente de trabajo. El servidor público, su representante legal o sus familiares derechohabientes, también podrán dar el <b>aviso de referencia</b>, cuando el ente público omita hacerlo.”</Typography>
        </div>
      </div>

    </Layout>
  );
};

export default AccidenteTrabajo;